import clsx from 'clsx';
import { ParagraphAndImageRowParagraphProps } from 'interfaces/cms/content';
import BasicLinkComponent from 'components/common/Link';
import Image from 'components/common/Image';
import Markdown from 'components/common/Markdown';
import MarkdownWithBullet from 'components/common/MarkdownWithBullet';
import ButtonComponent from 'components/common/Button';
import { rem } from 'lib';
import styles from './Paragraph.module.scss';

function Paragraph({
  contentPosition,
  boxBackground,
  title,
  subtitle,
  align,
  titleColor,
  subtitleColor,
  imageStyle,
  image,
  button,
  imgUrl,
  imgPageSlug,
  bulletColor,
}: ParagraphAndImageRowParagraphProps) {
  function renderBoxStyle() {
    if (boxBackground) {
      return {
        background: '#fff',
        padding: rem(20),
        borderRadius: rem(20),
        boxShadow: `${rem(1)} ${rem(10)} ${rem(15)} ${rem(
          -3
        )} rgba(0, 0, 0, 0.16)`,
      };
    }
    return {};
  }

  function renderPositionContent() {
    if (contentPosition === 'under_the_image') {
      return `${styles.under_the_image}`;
    }
    if (contentPosition === 'before_the_image') {
      return `${styles.before_the_image}`;
    }
    return `${styles.next_to_the_image}`;
  }

  function renderImageStyle() {
    if (imageStyle === 'circle') {
      return `${styles.circle}`;
    }
    if (imageStyle === 'square') {
      return `${styles.square}`;
    }
    return `${styles.rectangle}`;
  }

  /**
   * Get image size based on styles
   */
  const imageSize = {
    width: imageStyle === 'rectangle' ? 160 : 90,
    height: 90,
  };

  const alignConfig = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
    justify: 'text-justify',
  };

  return (
    <div
      className={clsx(
        `w-[100%] p-[20px] ${alignConfig[align]}`,
        renderPositionContent()
      )}
      style={renderBoxStyle()}
    >
      <div className={clsx('flex items-start', styles.paragraphFlex)}>
        {image && (imgUrl || imgPageSlug) && (
          <div
            className={clsx(
              'flex justify-center',
              styles.image,
              renderImageStyle()
            )}
          >
            <BasicLinkComponent
              href={(imgPageSlug && `/${imgPageSlug}`) || imgUrl}
              isExternalLink={!!imgUrl}
            >
              <div className={`${alignConfig[align]}`}>
                <Image
                  src={image.url}
                  alt={image.alt}
                  width={imageSize.width}
                  height={imageSize.height}
                  sizes="100vw"
                  style={{
                    width:
                      imageStyle === 'rectangle' &&
                      contentPosition === 'under_the_image'
                        ? '100%'
                        : imageSize.width,
                  }}
                />
              </div>
            </BasicLinkComponent>
          </div>
        )}

        {image && !imgUrl && !imgPageSlug && (
          <div
            className={clsx(
              'flex justify-center',
              styles.image,
              styles.image__nolink,
              renderImageStyle()
            )}
          >
            <Image
              src={image.url}
              alt={image.alt}
              width={imageSize.width}
              height={imageSize.height}
              sizes="100vw"
              style={{
                width:
                  contentPosition === 'under_the_image' &&
                  Number(imageSize.width) > 100
                    ? '100%'
                    : imageSize.width,
              }}
              priority
            />
          </div>
        )}

        <div className="w-[100%]">
          <div className={clsx(styles.paragraph_title)}>
            <Markdown md={title} textAlign={align} textColor={titleColor} />
          </div>
          <div className={clsx('mt-[0.625rem]', styles.paragraph_text)}>
            <MarkdownWithBullet
              bulletColor={bulletColor}
              subtitle={subtitle}
              align={align}
              subtitleColor={subtitleColor}
            />
          </div>
        </div>
      </div>

      {button && (button.buttonPageSlug || button.buttonUrl) && (
        <>
          <div
            className={clsx(
              'pt-[1.25rem] ml-auto mr-auto',
              styles.paragraphButton
            )}
          >
            <BasicLinkComponent
              href={
                !button.buttonPageSlug
                  ? button.buttonUrl
                  : button.buttonPageSlug.slug
              }
              rel={button.buttonLinkRel}
              isExternalLink={!button.buttonPageSlug}
              passHref
            >
              <ButtonComponent {...button} />
            </BasicLinkComponent>
          </div>
          {button.disclaimer && (
            <div
              className={clsx(
                'pt-[0.625rem] w=[100%] flex items-start justify-center',
                styles.button_disclaimer
              )}
            >
              <Markdown
                md={button.disclaimer.title}
                textColor={button.disclaimer.color}
                textAlign="center"
                hasLockIcon={button.disclaimer?.lockIcon}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Paragraph;
